import React from 'react'
import { StaticImage } from 'gatsby-plugin-image'
import Layout from '../../../layouts'
import LargeHero from '../../../components/elements/largeHero'
import { Body, SizedText, Canon } from '../../../components/elements/typography'
import Grid from '../../../components/elements/grid'
import ExternalLink from '../../../components/elements/externalLink'
import SEO from '../../../components/elements/seo'

const OpenPositionsPage = () => {
  return (
    <Layout isSubMenuOpen={true} openAccountLink={true}>
       <SEO
        title="Open Positions - Careers at The Claxton Bank"
        description="Browse all open positions and apply for your dream job online."
        location="/about/careers/openings"
        ogImg='/images/social-graph/the-claxton-bank.jpg'
      />

      {/* -- HERO SECTION -- */}
      <LargeHero.Container
        padding="3rem 0 0 0"
        background={{
          backgroundImage: `url("/images/officeBG.jpg")`, 
          backgroundSize: `cover`,
          backgroundPosition: `center`,
        }}
        breadcrumbLinks={[
          { path: "/", text: "Home" },
          { path: "/about/", text: "About Us" },
          { path: "/about/careers/", text: "Careers" },
          { path: "/about/careers/openings", text: "Openings" }
        ]}
        styles={{
          overflow: `hidden`,
          '#hero': {gridColumnEnd: `span 12` },
          '.heroImage': { marginTop: `-2rem`, alignSelf: `end`,},
          '@media (min-width: 680px)': {
            '.heroContent': { width: `calc(50% - 1rem)`, marginTop: `-2rem`},
            '.heroImage': { width: `calc(35% - 1rem) !important`, marginTop:`0`, },
          },
          '@media (min-width: 680px)': {
            '.heroContent': { width: `calc(55% - 1rem)`, marginTop: `-2rem`},
            '.heroImage': { width: `calc(30% - 1rem) !important`, marginTop:`0`, },
          },
          '@media (min-width: 1000px)': { '.heroImage': {marginTop: `-4rem`,}}
        }}
      >
        <LargeHero.Content styles={{paddingBottom: `4rem`}}>
          <LargeHero.Eyebrow text="About Us" />
          <h1 css={{fontFamily: `Proxima-nova`, textTransform: `uppercase`,  'span': {display: `block`, lineHeight: `1`, padding: `0.5rem 0`}}}>
            <SizedText as="span" smallSize="2.281" largeSize="5.202" styleOverrides={{'@media only screen and (min-device-width: 481px) and (max-device-width: 1024px) and (orientation:portrait)':{fontSize: `2.881rem !important`}}}>Browse Open Positions</SizedText>
          </h1>
          <SizedText as="p" smallSize="1.602" largeSize="2.027" styleOverrides={{ fontFamily: `source-sans-pro !important`, fontWeight: `300 !important` }}>And apply for your dream job now all online with a few clicks!</SizedText>
        </LargeHero.Content>
        <LargeHero.Image >
          <StaticImage 
            css={{
              '@media (min-width: 680px)': { display: 'none !important' }, 
              '@media (min-width: 1300px)': { display: 'block !important' } 
            }} 
            src="../../../images/careers/job-application.png"  
            alt="TCB Employees - Linda Davidson, Renee Glover, Michelle Pollard"
          />
          <StaticImage 
            css={{
              display: `none !important`,  
              '@media (min-width: 680px)': { display: 'block !important' }, 
              '@media (min-width: 1300px)': { display: 'none !important' } 
            }} 
            src="../../../images/careers/job-application.png"  
            alt="TCB Employees - Michelle Pollard, Renee Glover"
          />
        </LargeHero.Image>
      </LargeHero.Container>

      <Grid.FullWidthSection>
        <Grid.Block start={[2,2,2,2,2,3]} span={[12,12,12,12,12,10]} css={{padding: `6rem 0`, textAlign: `left`}}>
        <Canon>
              Senior Credit Leader	
          </Canon>
          <p>
              <b>Branch/Dept:</b> Executive / Credit <br />
              <b>FLSA Status: </b> Exempt	<br />
              <b>Reports to:</b> President & CEO 	<br /><br />

              <b>SUMMARY</b>
              The Claxton Bank is a community bank headquartered in Claxton, Georgia with banking offices located in Claxton and Richmond Hill. The bank is embarking on a strategic expansion into Jacksonville, Florida and Savannah, Georgia. The Senior Credit Leader will assist Bank management and lending personnel to properly structure and handle our customer's credit needs, including the proper credit structure for complex transactions, adherence to Federal and State Regulations, Bank policies and to protect the Bank's assets at all times from undue credit risk or exposure. This role is responsible for all aspects of the credit risk management function including design, execution, communication of policy, approval processes, administration, and portfolio analysis. This will involve working closely with all members of the executive team, the Board of Directors, regulators, and individuals at all levels within the organization. 
              <br /><br />
              <b>ESSENTIAL FUNCTIONS</b>
          </p>
          <ul>
<li>Refine and implement a data-driven credit risk framework consistent with the Bank’s overall credit risk appetite.</li>
<li>Effectively balance the bank's goals of disciplined risk management, sound credit quality and underwriting and loan portfolio growth.</li>
<li>Partner with lending teams to review credits submitted for approval and serve as a voting member of the Management Loan Committee. </li>
<li>A demonstrated ability to work effectively with minimal supervision and take initiative in problem solving.</li>
<li>Maintain and update, as necessary, the bank’s loan policies and guidelines.</li>
<li>Knowledge of loan documentation requirements for all types of commercial loan and lease products including C&I loans, asset-based loans, commercial real estate loans, and equipment leasing. </li>
<li>Prepare and calculate monthly ALLL and make recommendations to executive management and the Board of Directors for the amount to be added/deducted to/from the loan reserve.</li>
<li>Prepare and distribute credit information covering loan quality trends, growth trends, loan product concentrations.</li>
<li>Assume responsibility for special projects; gather data and prepare reports for the Board of Directors and other members of Bank management, audits and other personnel.</li>
<li>Oversee the credit approval process, as well as portfolio monitoring systems for all current and future credit products.</li>
<li>Ensure that credit policies are routinely reviewed, modified as necessary, and well-communicated throughout the organization.</li>
                </ul>

                <p><b>REQUIRED EDUCATION, EXPERIENCE, AND SKILLS</b></p>
                <ul>
                  <li>4-year college degree </li>
<li>At least 10 years of banking experience, including formal credit training, in progressively senior credit roles and responsibility.</li>
<li>Excellent written and verbal communication skills, computer proficiency and attention to detail</li>
<li>Strong organizational skills, good judgment, and decision-making skills.</li>
</ul>

<p><b>PHYSICAL REQUIREMENTS</b></p>
<ul>
<li>Must be able to drive a car.</li>
<li>Must be cable of operating all types of office equipment.</li>
</ul>

<p><b>SUPERVISORY REQUIREMENTS</b></p>
<ul>
<li>Potential to manage 1-2 employees. </li>
</ul>

<p><b>WORKING CONDITIONS</b></p>
<ul>
<li>Requires contact with the general public.</li>
<li>	May require travel to other offices to provide assistance or attend meetings. Also, travel for seminars and training </li>
</ul>

<p>The requirements listed above are representative of the knowledge, skill, and/or ability required. Reasonable accommodations can be made to enable individuals with disabilities to perform the essential functions.</p> <br /><br />
<p>Interested candidates must submit their resume to:<br />
The Claxton Bank, Attn: Human Resources, P.O. Box 247, 
Claxton, Georgia 30417< br />
or email <a href="mailto:hr@tcbga.bank ">hr@tcbga.bank</a><br />
Equal Employment Opportunity
</p>
              
        </Grid.Block>
      </Grid.FullWidthSection>

    </Layout>
    
  )
}

export default OpenPositionsPage



